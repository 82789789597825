import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export const adminColumns = [
  { field: "_id", headerName: "ADMIN ID", width: 230 },
  {
    field: "emailId",
    headerName: "Email",
    width: 230,
  },
  {
    field: "firstName",
    headerName: "First Name",
    width: 100,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 100,
  },
];

export const userTypeColumns = [
  { field: "_id", headerName: "User Type Id", width: 230 },
  { field: "userType", headerName: "User Type", width: 130 },
];

export const userColumns = [
  { field: "userId", headerName: "USER ID", width: 230 },
  {
    field: "emailId",
    headerName: "Email",
    width: 290,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row.img ? (
            <img
              className="cellImg"
              src={params.row.userPicture}
              alt="avatar"
            />
          ) : (
            <AccountCircleIcon style={{ color: "#489FC5" }} />
          )}

          {params.row.emailId}
        </div>
      );
    },
  },
  {
    field: "salutation",
    headerName: "Salutation",
    width: 100,
  },
  {
    field: "firstName",
    headerName: "First Name",
    width: 100,
  },
  {
    field: "middleName",
    headerName: "Middle Name",
    width: 100,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 100,
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: 200,
  },
  {
    field: "flag",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.flag}`}>
          {params.row.flag}
        </div>
      );
    },
  },
  {
    field: "userPicture",
    headerName: "User Picture",
    hide: true,
    width: 200,
  },
  {
    field: "gender",
    headerName: "Gender",
    width: 200,
  },
  {
    field: "dateOfBirth",
    headerName: "DOB",
    hide: true,
    width: 200,
  },
  {
    field: "age",
    headerName: "Age",
    hide: true,
    width: 200,
  },
  {
    field: "organization",
    headerName: "Organization",
    width: 200,
  },
  {
    field: "designation",
    headerName: "Designation",
    hide: true,
    width: 200,
  },
  {
    field: "officialEmailId",
    headerName: "Official Email Id",
    width: 290,
  },
  {
    field: "Mobile Number 1",
    headerName: "mobileNumber1",
    hide: true,
    width: 200,
  },
  {
    field: "Landline Number 1",
    headerName: "landlineNumber1",
    hide: true,
    width: 200,
  },
  {
    field: "address",
    headerName: "Address",
    hide: true,
    width: 200,
  },
  {
    field: "city",
    headerName: "city",
    hide: true,
    width: 200,
  },
  {
    field: "state",
    headerName: "state",
    hide: true,
    width: 200,
  },
  {
    field: "country",
    headerName: "country",
    hide: true,
    width: 200,
  },
  {
    field: "previousOrganization",
    headerName: "previousOrganization",
    hide: true,
    width: 200,
  },
  {
    field: "activeUserId",
    headerName: "activeUserId",
    hide: true,
    width: 200,
  },
  {
    field: "userProfile",
    headerName: "userProfile",
    hide: true,
    width: 200,
  },
  {
    field: "userType",
    headerName: "userType",
    type: "string",
    valueGetter: ({ value }) => {
      var arr = [];
      for (var i = 0; i < value.length; i++) {
        arr.push(value[i].userType);
      }
      return arr.toString();
    },
    width: 400,
  },
  {
    field: "accountManager",
    headerName: "accountManager",
    hide: true,
    width: 200,
  },
  {
    field: "remarks",
    headerName: "remarks",
    hide: true,
    width: 200,
  },
  {
    field: "remarksDate",
    headerName: "remarksDate",
    hide: true,
    width: 200,
    type: "date",
    renderCell: (params) => {
      const date = new Date(params.row.remarksDate);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return params.row.remarksDate === null
        ? ""
        : date.toLocaleDateString("en-GB", options);
    },
  },
  {
    field: "updatedBy",
    headerName: "updatedBy",
    hide: true,
    width: 200,
  },
  {
    field: "profileComplete",
    headerName: "profileComplete",
    width: 200,
  },
  {
    field: "newsLetterSubscription",
    headerName: "newsLetterSubscription",
    width: 200,
  },
];

export const eventTypeColumns = [
  { field: "_id", headerName: "Event Type Id", width: 230 },
  {
    field: "type",
    headerName: "Type",
    width: 130,
  },
];

export const eventColumns = [
  { field: "eventId", headerName: "Event Id", width: 230 },
  {
    field: "eventName",
    headerName: "Event Name",
    width: 250,
  },
  {
    field: "eventModes",
    headerName: "Event Mode",
    valueGetter: ({ value }) => value.mode,
    width: 100,
    renderCell: (params) => {
      return params.row.eventModes.mode;
    },
  },
  {
    field: "eventType",
    headerName: "Event Type",
    type: "string",
    valueGetter: ({ value }) => value.type,
    width: 200,
    renderCell: (params) => {
      return params.row.eventType.type;
    },
  },

  {
    field: "startDate",
    headerName: "Start Date",
    width: 100,
    type: "date",
    renderCell: (params) => {
      const date = new Date(params.row.startDate);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleDateString("en-GB", options);
    },
  },
  {
    field: "endDate",
    headerName: "End Date",
    width: 100,
    type: "date",
    renderCell: (params) => {
      const date = new Date(params.row.endDate);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleDateString("en-GB", options);
    },
  },

  {
    field: "publish",
    headerName: "Publish",
    width: 80,
  },

  {
    field: "pinned",
    headerName: "Pinned",
    width: 100,
  },
  {
    field: "eventDescription",
    headerName: "eventDescription",
    hide: true,
    width: 200,
  },

  {
    field: "eventBannerImage",
    headerName: "eventBannerImage",
    hide: true,
    width: 200,
  },

  {
    field: "templateNo",
    headerName: "templateNo",
    hide: true,
    width: 200,
  },
];

export const eventModeColumns = [
  { field: "_id", headerName: "Event Mode Id", width: 230 },
  {
    field: "mode",
    headerName: "Mode",
    width: 130,
  },
];

export const editorialTypeColumns = [
  { field: "_id", headerName: "Editorial Type Id", width: 230 },
  {
    field: "type",
    headerName: "Type",
    width: 130,
  },
];

export const editorialColumns = [
  { field: "editorialId", headerName: "Editorial ID", width: 230 },
  {
    field: "editorialType",
    headerName: "Editorial Type",
    width: 150,
    valueGetter: ({ value }) => value && value.type,
    renderCell: (params) => {
      return params.row.editorialType.type;
    },
  },
  {
    field: "headLine",
    headerName: "Headline",
    width: 250,
  },
  {
    field: "description",
    headerName: "Description",
    width: 300,
  },
  {
    field: "mediaLink",
    headerName: "Media Link",
    width: 250,
    renderCell: (params) => {
      return <a href={params.row.mediaLink}>{params.row.mediaLink}</a>;
    },
  },
  {
    field: "tags",
    headerName: "Tags",
    width: 200,
  },
  {
    field: "publish",
    headerName: "Publish",
    width: 100,
  },
];
export const commentColumns = [
  
  {
    field: "comment",
    headerName: "Comments",
    width: 500,
  },
  // {
  //   field: "publish",
  //   headerName: "Publish",
  //   width: 100,
  // },
  {
    field: "name",
    headerName: "Name",
    width: 300,
  },
  { field: "_id", headerName: "Comment ID", width: 230 },
  {
    field: "editorialId",
    headerName: "Editorial ",
    width: 150,
    valueGetter: ({ value }) => value && value.type,
    renderCell: (params) => {
      return params.row.editorialId;
    },
  },
  
];


export const vohTvTypeColumns = [
  { field: "_id", headerName: "Voh Tv Type Id", width: 230 },
  {
    field: "type",
    headerName: "Type",
    width: 130,
  },
];

export const vohTvMainPageColumns = [
  { field: "_id", headerName: "Voh Tv Main Page Id", width: 230 },
  {
    field: "vohTvType",
    headerName: "Voh Tv Type",
    width: 150,
    valueGetter: ({ value }) => value && value.type,
    renderCell: (params) => {
      return params.row.vohTvType.type;
    },
  },
  {
    field: "heading",
    headerName: "Heading",
    width: 250,
  },
  {
    field: "bannerImage",
    headerName: "Banner Image",
    width: 300,
    renderCell: (params) => {
      return <a href={params.row.bannerImage}>{params.row.bannerImage}</a>;
    },
  },
];

export const vohTvColumns = [
  { field: "vohTvId", headerName: "Voh Tv Id", width: 230 },
  {
    field: "vohTvType",
    headerName: "Voh Tv Type",
    width: 150,
    valueGetter: ({ value }) => value && value.type,
    renderCell: (params) => {
      return params.row.vohTvType.type;
    },
  },
  {
    field: "vohTvDate",
    headerName: "Voh Tv Date",
    width: 100,
    type: "date",
    renderCell: (params) => {
      const date = new Date(params.row.vohTvDate);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return params.row.vohTvDate === null
        ? ""
        : date.toLocaleDateString("en-GB", options);
    },
  },
  {
    field: "vohTvTime",
    headerName: "Voh Tv Time",
    width: 100,
    renderCell: (params) => {
      const date = new Date(params.row.vohTvTime);
      return params.row.vohTvTime === null ? "" : date.toLocaleTimeString();
    },
  },
  {
    field: "headLine",
    headerName: "Headline",
    width: 250,
  },
  {
    field: "description",
    headerName: "Description",
    width: 300,
  },
  {
    field: "mediaLink",
    headerName: "Media Link",
    width: 250,
    renderCell: (params) => {
      return <a href={params.row.mediaLink}>{params.row.mediaLink}</a>;
    },
  },
  {
    field: "embeddedLink",
    headerName: "Embedded Link",
    width: 250,
    renderCell: (params) => {
      return <a href={params.row.embeddedLink}>{params.row.embeddedLink}</a>;
    },
  },
  {
    field: "publish",
    headerName: "Publish",
    width: 100,
  },
];

export const galleryColumns = [
  {
    field: "_id",
    headerName: "Gallery Id",
    width: 230,
  },
  { field: "eventId", headerName: "Event Id", width: 230 },
  {
    field: "eventName",
    headerName: "Event Name",
    width: 250,
    renderCell: (params) => {
      return params.row.event.eventName;
    },
  },
  {
    field: "userId",
    headerName: "User Id",
    width: 250,
  },
  // {
  //   field: "fileName",
  //   headerName: "File Name",
  //   width: 250,
  // },
  // {
  //   field: "fileLocation",
  //   headerName: "File Location",
  //   width: 250,
  //   renderCell: (params) => {
  //     return (
  //       <a href={params.row.fileLocation}>
  //         {params.row.fileLocation}
  //       </a>
  //     );
  //   }
  // }
];

export const newsLetterEmailsColumns = [
  // { field: "_id",
  //   headerName: "Voh Tv Main Page Id",
  //   width: 230
  // },
  {
    field: "emailId",
    headerName: "Emails",
    width: 250,
  },
  {
    field: "createdAt",
    headerName: "Registered On",
    width: 130,
    type: "date",
    renderCell: (params) => {
      const date = new Date(params.row.createdAt);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleDateString("en-GB", options);
    },
  },
];

export const collaborationColumns = [
  { field: "_id", headerName: "Collaboration Id", width: 230 },
  {
    field: "companyName",
    headerName: "Company Name",
    width: 200,
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
  },
  {
    field: "mobileNo",
    headerName: "Mobile No",
    width: 150,
  },
  // {
  //   field: "designation",
  //   headerName: "Designation",
  //   width: 200,
  // },

  // {
  //   field: "companyAddress",
  //   headerName: "Company Address",
  //   width: 300,
  // },
];
