// import { useState } from "react";
// import RichTextEditor from "../Event/RichEditor";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";
// import { Typography } from "@mui/material";
// function EventGallery({ eventGalleryData, SetEventGalleryData }) {
//   const addData = () => {
//     SetEventGalleryData({
//       groupHeading: "Event Gallery",
//       description: "",
//       position: 0,
//       publish: false,
//       data: [],
//     });
//   };

//   const removeData = () => {
//     SetEventGalleryData(null);
//   };

//   const handleAddPreviousEvent = () => {
//     if (eventGalleryData) {
//       const updatedData = { ...eventGalleryData };
//       updatedData.data.push({
//         fileType: "image",
//         videoUrl: "",
//         image: "",
//         eventLink: "",
//         pinned: false,
//       });
//       SetEventGalleryData(updatedData);
//     }
//   };

//   const handleRemovePreviousEvent = () => {
//     if (eventGalleryData && eventGalleryData.data.length > 0) {
//       const updatedData = { ...eventGalleryData };
//       updatedData.data.pop();
//       SetEventGalleryData(updatedData);
//     }
//   };

//   const updatePreviousEventData = (index, key, value) => {
//     SetEventGalleryData((prev) => ({
//       ...prev,
//       data: prev.data.map((item, i) =>
//         i === index ? { ...item, [key]: value } : item
//       ),
//     }));
//   };

//   const [hide, setHide] = useState(true);
//   const [hideStatus, setHideStatus] = useState("Show");

//   return (
//     <>
//       <div className="h-20 bg-[#489FC5] my-10 rounded-md">
//         <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
//           <div className="w-[250px] flex justify-around">
//             Event Gallery
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 setHide(!hide);
//                 setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
//               }}
//             >
//               ({hideStatus})
//             </button>
//           </div>
//           {eventGalleryData === null ? (
//             <AddIcon
//               onClick={() => addData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           ) : (
//             <RemoveIcon
//               onClick={() => removeData()}
//               style={{
//                 fontSize: "48px",
//                 margin: "0 0 0 40px",
//                 cursor: "pointer",
//               }}
//             />
//           )}
//         </h1>
//       </div>
//       {eventGalleryData !== null && (
//         <div
//           style={{
//             visibility: hide ? "hidden" : "visible",
//             height: hide ? "0" : "auto",
//           }}
//         >
//           <form action="">
//             <div className="mx-auto w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Group Heading
//               </label>
//               <RichTextEditor
//                 name={"groupHeading"}
//                 value={eventGalleryData.groupHeading}
//                 onChange={(e) => {
//                   SetEventGalleryData((prev) => ({
//                     ...prev,
//                     groupHeading: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className="mx-auto w-11/12  mb-12">
//               <label
//                 htmlFor=""
//                 className=" mb-6   text-xl  font-medium text-[#489FC5]"
//               >
//                 Description
//               </label>
//               <RichTextEditor
//                 name={"description"}
//                 value={eventGalleryData.description}
//                 onChange={(e) => {
//                   SetEventGalleryData((prev) => ({
//                     ...prev,
//                     description: e.target.value,
//                   }));
//                 }}
//               />
//             </div>

//             <div className="flex gap-8 mx-auto w-11/12 mb-4">
//               <div className="flex-1">
//                 <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
//                   Position
//                 </label>
//                 <input
//                   type="number"
//                   value={eventGalleryData.position}
//                   onChange={(e) => {
//                     SetEventGalleryData((prev) => ({
//                       ...prev,
//                       position: e.target.value,
//                     }));
//                   }}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-full    "
//                 />
//               </div>
//               <div className=" flex-1 ">
//                 <div className=" ">
//                   <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
//                     Publish :
//                     <select
//                       value={eventGalleryData.publish}
//                       onChange={(e) => {
//                         SetEventGalleryData((prev) => ({
//                           ...prev,
//                           publish: e.target.value === "true",
//                         }));
//                       }}
//                     >
//                       <option value="true">True</option>
//                       <option value="false">False</option>
//                     </select>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             <div>
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   color: "#489FC5",
//                   fontSize: "30px",
//                   margin: "40px 0 0 68px",
//                 }}
//               >
//                 Previous Event Data
//                 <AddCircleIcon
//                   onClick={() => handleAddPreviousEvent()}
//                   style={{ fontSize: "36px", margin: "0 0 0 40px" }}
//                 />
//                 <RemoveCircleIcon
//                   onClick={() => handleRemovePreviousEvent()}
//                   style={{ fontSize: "36px", margin: "0 0 0 20px" }}
//                 />
//               </Typography>
//               <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//               {eventGalleryData.data.map((_, index) => (
//                 <div
//                   style={{
//                     visibility: hide ? "hidden" : "visible",
//                     height: hide ? "0" : "auto",
//                     width:
//                       eventGalleryData.data[index].fileType === "video"
//                         ? "100%"
//                         : "100%", // Adjusted width for video type
//                   }}
//                 >
//                   <div className="flex flex-wrap w-11/12 gap-8 my-6 mb-10 mx-auto">
//                     <div className="flex-1  w-full">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         File Type
//                       </label>
//                       <select
//                         value={eventGalleryData.data[index].fileType}
//                         onChange={(e) =>
//                           updatePreviousEventData(
//                             index,
//                             "fileType",
//                             e.target.value
//                           )
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       >
//                         <option value="image">Image</option>
//                         <option value="video">Video</option>
//                       </select>
//                     </div>
//                     {/* {eventGalleryData.data[index].fileType === "video" && ( */}
//                     <div className="flex-1 w-full">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Video URL
//                       </label>
//                       <input
//                         value={eventGalleryData.data[index].videoUrl}
//                         type="text"
//                         onChange={(e) => {
//                           updatePreviousEventData(
//                             index,
//                             "videoUrl",
//                             e.target.value
//                           );
//                         }}
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       />
//                       {/* {eventGalleryData.data[index].videoUrl && (
//                         <video
//                           src={eventGalleryData.data[index].videoUrl}
//                           controls
//                           className="mt-4 w-full max-w-[500px] rounded-md"
//                           onError={(e) => {
//                             e.target.style.display = 'none';
//                             console.error('Error loading video:', e); // Log error to console instead of using alert
//                             console.error('Possible reasons: invalid URL, video format not supported, or video not accessible from the server.');
//                           }} // Hide video element and log detailed error reasons to console if there's an error loading the video
//                         ></video>
//                       )} */}
//                     </div>
//                     {/* )} */}
//                     {/* {eventGalleryData.data[index].fileType === "image" && ( */}
//                     <div className="flex-1 w-full">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Image
//                       </label>
//                       <input
//                         type="file"
//                         onChange={(e) => {
//                           const file = e.target.files[0];
//                           const reader = new FileReader();
//                           reader.onloadend = () => {
//                             updatePreviousEventData(index, "image", file);
//                           };
//                           if (file) {
//                             reader.readAsDataURL(file);
//                           }
//                         }}
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       />
//                       <img
//                         src={eventGalleryData.data[index].image}
//                         alt="Uploaded"
//                         className="mt-4 w-full object-cover max-w-[500px] rounded-md"
//                       />
//                     </div>
//                     {/* )} */}
//                   </div>
//                   <div className="flex flex-wrap w-11/12 gap-8 my-6 mb-10 mx-auto">
//                     <div className="flex-1">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Event Link
//                       </label>
//                       <input
//                         value={eventGalleryData.data[index].eventLink}
//                         type="url"
//                         onChange={(e) =>
//                           updatePreviousEventData(
//                             index,
//                             "eventLink",
//                             e.target.value
//                           )
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       />
//                     </div>
//                     <div className="flex-1">
//                       <label className="mb-6 text-xl font-medium text-[#489FC5]">
//                         Pinned
//                       </label>
//                       <select
//                         value={
//                           eventGalleryData.data[index].pinned ? "true" : "false"
//                         }
//                         onChange={(e) =>
//                           updatePreviousEventData(
//                             index,
//                             "pinned",
//                             e.target.value === "true"
//                           )
//                         }
//                         className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
//                       >
//                         <option value="true">Pinned</option>
//                         <option value="false">Unpinned</option>
//                       </select>
//                     </div>
//                   </div>

//                   <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
//                 </div>
//               ))}
//             </div>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default EventGallery;


import { useState } from "react";
import RichTextEditor from "../Event/RichEditor";
import { Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

function EventGallery({ eventGalleryData, SetEventGalleryData }) {
  const addData = () => {
    SetEventGalleryData([
      ...eventGalleryData,
      {
        groupHeading: "Event Gallery",
        description: "",
        position: 0,
        publish: false,
        data: [],
      },
    ]);
    toast.success("New Event Gallery group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (eventGalleryData.length > 0) {
      const updatedData = [...eventGalleryData];
      updatedData.pop();
      SetEventGalleryData(updatedData);
      toast.success("Last Event Gallery group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No Event Gallery groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const removeData = (index) => {
    if (window.confirm("Are you sure you want to delete this Event Gallery section?")) {
      SetEventGalleryData(eventGalleryData.filter((_, i) => i !== index));
      toast.success("Event Gallery group deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAddGalleryItem = (groupIndex) => {
    const updatedData = [...eventGalleryData];
    updatedData[groupIndex].data.push({
      fileType: "image",
      videoUrl: "",
      image: "",
      eventLink: "",
      pinned: false,
      publishAt:Date.now()
    });
    SetEventGalleryData(updatedData);
  };

  const handleRemoveGalleryItem = (groupIndex) => {
    const updatedData = [...eventGalleryData];
    if (updatedData[groupIndex].data.length > 0) {
      updatedData[groupIndex].data.pop();
      SetEventGalleryData(updatedData);
    }
  };

  const updateGalleryItemData = (groupIndex, itemIndex, key, value) => {
    const updatedData = [...eventGalleryData];
    updatedData[groupIndex].data[itemIndex] = {
      ...updatedData[groupIndex].data[itemIndex],
      [key]: value,
    };
    SetEventGalleryData(updatedData);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[250px] flex justify-around">
            Event Gallery
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {eventGalleryData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </h1>
      </div>
      {eventGalleryData.map((galleryGroup, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className='mt-1 border-2 border-solid border-[#489FC5] rounded-md'
        >
          <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px"
              }}
            />
          </div>
          <form action="">
            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Group Heading
              </label>
              <RichTextEditor
                name={"groupHeading"}
                value={galleryGroup.groupHeading}
                onChange={(e) => {
                  const updatedData = [...eventGalleryData];
                  updatedData[groupIndex].groupHeading = e.target.value;
                  SetEventGalleryData(updatedData);
                }}
              />
            </div>

            <div className="mx-auto w-11/12  mb-12">
              <label
                htmlFor=""
                className=" mb-6   text-xl  font-medium text-[#489FC5]"
              >
                Description
              </label>
              <RichTextEditor
                name={"description"}
                value={galleryGroup.description}
                onChange={(e) => {
                  const updatedData = [...eventGalleryData];
                  updatedData[groupIndex].description = e.target.value;
                  SetEventGalleryData(updatedData);
                }}
              />
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={galleryGroup.position}
                  onChange={(e) => {
                    const updatedData = [...eventGalleryData];
                    updatedData[groupIndex].position = e.target.value;
                    SetEventGalleryData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-full    "
                />
              </div>
              <div className=" flex-1 ">
                <div className=" ">
                  <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
                    Publish :
                    <select
                      value={galleryGroup.publish}
                      onChange={(e) => {
                        const updatedData = [...eventGalleryData];
                        updatedData[groupIndex].publish = e.target.value === "true";
                        SetEventGalleryData(updatedData);
                      }}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#489FC5",
                  fontSize: "30px",
                  margin: "40px 0 0 68px",
                }}
              >
                Gallery Items
                <AddCircleIcon
                  onClick={() => handleAddGalleryItem(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                />
                <RemoveCircleIcon
                  onClick={() => handleRemoveGalleryItem(groupIndex)}
                  style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                />
              </Typography>
              <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
              {galleryGroup.data.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="flex flex-wrap w-11/12 gap-8 my-6 mb-10 mx-auto">
                    <div className="flex-1  w-full">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        File Type
                      </label>
                      <select
                        value={item.fileType}
                        onChange={(e) =>
                          updateGalleryItemData(
                            groupIndex,
                            itemIndex,
                            "fileType",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      >
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                      </select>
                    </div>
                    <div className="flex-1 w-full">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Video URL
                      </label>
                      <input
                        value={item.videoUrl}
                        type="text"
                        onChange={(e) => {
                          updateGalleryItemData(
                            groupIndex,
                            itemIndex,
                            "videoUrl",
                            e.target.value
                          );
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>
                    <div className="flex-1 w-full">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Image
                      </label>
                      <input
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            updateGalleryItemData(groupIndex, itemIndex, "image", file);
                          };
                          if (file) {
                            reader.readAsDataURL(file);
                          }
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                      <img
                        src={item.image}
                        alt="Uploaded"
                        className="mt-4 w-full object-cover max-w-[500px] rounded-md"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap w-11/12 gap-8 my-6 mb-10 mx-auto">
                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Event Link
                      </label>
                      <input
                        value={item.eventLink}
                        type="url"
                        onChange={(e) =>
                          updateGalleryItemData(
                            groupIndex,
                            itemIndex,
                            "eventLink",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>
                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Pinned
                      </label>
                      <select
                        value={item.pinned ? "true" : "false"}
                        onChange={(e) =>
                          updateGalleryItemData(
                            groupIndex,
                            itemIndex,
                            "pinned",
                            e.target.value === "true"
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      >
                        <option value="true">Pinned</option>
                        <option value="false">Unpinned</option>
                      </select>
                    </div>
                    <div className="flex-1">
                      <label className="mb-6 text-xl font-medium text-[#489FC5]">
                        Publish at
                      </label>
                      <input
                        value={item.publishAt}
                        type="Date"
                        onChange={(e) =>
                          updateGalleryItemData(
                            groupIndex,
                            itemIndex,
                            "publishAt",
                            e.target.value
                          )
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                      />
                    </div>
                  </div>

                  <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                </div>
              ))}
            </div>
          </form>
        </div>
      ))}
    </>
  );
}

export default EventGallery;
