import "../singlepage.scss";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useCallback, useEffect } from "react";
import { Services } from "../../Services";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const CommentPage = () => {
  const { commentId } = useParams();
  const [commentData, setCommentData] = useState(null);

  const getComment = useCallback(async () => {
    try {
      const response = await Services.getComment(commentId);
      // console.log(response.data);
      setCommentData(response.data);
      toast.success("Comment fetched successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  }, [commentId]);

  useEffect(() => {
    getComment();
  }, [commentId, getComment]);

  return (
    <div className="single">
      <Sidebar />
      {commentData ? (
        <div className="singleContainer">
          <Navbar />
          <div className="top">
            <div className="left">
              <Link
                to={`/comments/update/${commentId}`}
                style={{ textDecoration: "none" }}
              >
                <div className="editButton">Edit</div>
              </Link>
              <h1 className="title">Information</h1>
              <div className="item">
                <div className="details">
                  <h1 className="itemTitle">{commentData.comment}</h1>
                  <div className="detailItem">
                    <span className="itemKey">Comment Id:</span>
                    <span className="itemValue">
                      {commentData._id}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Editorial Id:</span>
                    <span className="itemValue">
                      {commentData.editorialId}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Name:</span>
                    <span className="itemValue">
                      {commentData.name}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Date:</span>
                    <span className="itemValue">
                      {commentData.date
                        ? new Date(commentData.date).toLocaleDateString(
                            "en-GB",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : null}
                    </span>
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Publish:</span>
                    <span className="itemValue">
                      {commentData.publish ? "True" : "False"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CommentPage;
