import { useState } from "react";
import RichTextEditor from "../Event/RichEditor";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
// import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import React from "react";

const MiscellaneousEvent = ({
  miscellaneousDetailsData,
  setMiscellaneousDetailsData,
}) => {
  const addData = () => {
    setMiscellaneousDetailsData([
      ...miscellaneousDetailsData,
      {
        groupHeading: "Miscellaneous",
        description: "",
        position: 0,
        publish: false,
        image: "",
      },
    ]);
    toast.success("New Miscellaneous group added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const removeLastGroup = () => {
    if (miscellaneousDetailsData && miscellaneousDetailsData.length > 0) {
      const updatedData = [...miscellaneousDetailsData];
      updatedData.pop();
      setMiscellaneousDetailsData(updatedData.length > 0 ? updatedData : null);
      toast.success("Last group removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning("No groups to remove!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // const removeData = (index) => {
  //   if (window.confirm("Are you sure you want to delete this section?")) {
  //     const updatedData = miscellaneousDetailsData.filter((_, i) => i !== index);
  //     setMiscellaneousDetailsData(updatedData.length > 0 ? updatedData : null);
  //     toast.success("Miscellaneous group deleted successfully!", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");

  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[200px] flex justify-around">
            Miscellaneous
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          <div>
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
            {miscellaneousDetailsData && miscellaneousDetailsData.length > 0 && (
              <RemoveIcon
                onClick={() => removeLastGroup()}
                style={{
                  fontSize: "48px",
                  margin: "0 0 0 20px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </h1>
      </div>
      {miscellaneousDetailsData && miscellaneousDetailsData.map((miscGroup, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
          className="mt-1 border-2 border-solid border-[#489FC5] rounded-md"
        >
          {/* <div className="flex justify-between mr-4 text-red-600">
            <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
            <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px",
              }}
            />
          </div> */}
          <form action="">
            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Group Heading
                </label>
                <RichTextEditor
                  name={"groupHeading"}
                  value={miscGroup.groupHeading}
                  onChange={(e) => {
                    const updatedData = [...miscellaneousDetailsData];
                    updatedData[groupIndex].groupHeading = e.target.value;
                    setMiscellaneousDetailsData(updatedData);
                  }}
                />
              </div>
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Description
                </label>
                <RichTextEditor
                  name={"description"}
                  value={miscGroup.description}
                  onChange={(e) => {
                    const updatedData = [...miscellaneousDetailsData];
                    updatedData[groupIndex].description = e.target.value;
                    setMiscellaneousDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Image
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      const updatedData = [...miscellaneousDetailsData];
                      updatedData[groupIndex].image = file;
                      setMiscellaneousDetailsData(updatedData);
                    };
                    if (file) {
                      reader.readAsDataURL(file);
                    }
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
                <img
                  className="max-w-full max-h-full object-cover rounded-lg mt-4 aspect-auto"
                  src={miscGroup.image}
                  alt="Image_Uploaded"
                />
              </div>

              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Publish
                </label>
                <select
                  value={miscGroup.publish}
                  onChange={(e) => {
                    const updatedData = [...miscellaneousDetailsData];
                    updatedData[groupIndex].publish = e.target.value === "true";
                    setMiscellaneousDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={miscGroup.position}
                  onChange={(e) => {
                    const updatedData = [...miscellaneousDetailsData];
                    updatedData[groupIndex].position = e.target.value;
                    setMiscellaneousDetailsData(updatedData);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
            </div>
          </form>
        </div>
      ))}
    </>
  );
};

export default MiscellaneousEvent;