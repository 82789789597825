import "../new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import RichTextEditor from "../Event/RichEditor";

const UpdateCommentPage = ({ inputs, title }) => {
  const { commentId } = useParams();
  console.log(commentId)
  const navigate = useNavigate();

  const [commentData, setCommentData] = useState({
    _id: "",
    publish: false,
    editorialId:"",
    comment:""
  });

  const [commentTypes, setCommentTypes] = useState(null);

  useEffect(() => {
    // getComment();
    getAllEditorials();
  }, []);

  const getAllEditorials = async () => {
    try {
      const response = await Services.getAllEditorials();
      setCommentTypes(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };
  // const getComment = async () => {
  //   try {
  //     const response = await Services.getComment(commentId);
  //     console.log(response.data)
  //     setCommentData(response.data);
  //   } catch (error) {
  //     toast.error(
  //       error?.response?.data?.message
  //         ? error?.response?.data?.message
  //         : "Error!!",
  //       {
  //         position: toast.POSITION.TOP_RIGHT,
  //       }
  //     );
  //   }
  // };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (commentData.commentType === "default") {
      toast.error("Please select comment type", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var date = null;

    if (commentData.date) {
      date = new Date(commentData.date).toISOString();
    }

    const formData = new FormData();

    for (let key of Object.keys(commentData)) {
      formData.set(key, commentData[key]);
    }

    formData.set("date", date);
    formData.set("tags", commentData.tags.split(","));

    try {
      const response = await Services.updateComment(commentId, formData);
      toast.success("Comment Updated", {
        position: toast.POSITION.TOP_RIGHT,
      });

      navigate(`/comments/${response.data.commentId}`);
    } catch (error) {
      if (error?.response?.data?.message === "api-400-all-field-mandatory") {
        toast.error("Enter all mandatory fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      return setCommentData((prevValue) => {
        return {
          ...prevValue,
          [name]: files[0],
        };
      });
    }

    setCommentData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="px-8 py-4">
          <div className="py-4">
            <h1 className="text-3xl font-bold py-2 mb-6 text-gray-800 w-full bg-slate-200 text-center">
              {title}
            </h1>
          </div>
          {commentTypes ? (
            <div className="bg-white rounded-lg shadow-xl p-6">
              <form onSubmit={onSubmit} className="space-y-6 max-w-3xl mx-auto">
                {inputs.map((input) => (
                  <div
                    key={input.id}
                    className={`space-y-2 ${
                      input.name === "editorialId" ||
                      input.name === "commentId" ||
                      input.name === "date" ||
                      input.name === "publish"
                        ? "w-[48%] inline-block"
                        : "w-[100%]"
                    }`}
                  >
                    <label className="block text-sm font-medium text-gray-700">
                      {input.label}
                      {input.required && (
                        <span className="text-red-500">*</span>
                      )}
                    </label>
                    {input.name === "description" ? (
                      <RichTextEditor
                        name={input.name}
                        onChange={handleChange}
                        value={commentData[input.name]}
                        className="w-full"
                      />
                    )  : input.name === "publish" ? (
                      <select
                        value={commentData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                        className="mt-1 block w-[90%] rounded-md p-2 border-2 border-solid shadow-sm focus:border-[#028fa1] focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      >
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    ) : input.type === "quill" ? (
                      <RichTextEditor
                        name={input.name}
                        onChange={handleChange}
                        value={commentData[input.name]}
                      />
                    ) : input.type === "file" ? (
                      <input
                        type={input.type}
                        name={input.name}
                        placeholder={input.placeholder}
                        onChange={handleChange}
                        className="mt-1 block w-[90%] rounded-md p-2 border-2 border-solid shadow-sm focus:border-[#028fa1] focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    ) : (
                      <input
                        type={input.type}
                        name={input.name}
                        value={commentData[input.name]}
                        placeholder={input.placeholder}
                        onChange={handleChange}
                        className="mt-1 block w-[90%] rounded-md p-2 border-2 border-solid shadow-sm focus:border-[#028fa1] focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    )}
                  </div>
                ))}

                <div className="flex justify-center">
                  <button className="w-auto px-6 bg-[#479fc5] text-white py-2 rounded-md hover:bg-[#028fa1] focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-out">
                    Update Comment
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateCommentPage;
