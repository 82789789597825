import "./datatable.scss";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
} from "@mui/x-data-grid";
import { commentColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { adminType } from "../../constants";
import { useState } from "react";
import { Services } from "../../Services"
import { toast } from "react-toastify";

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 1.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
      {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }} /> */}
    </Box>
  );
}

const CommentDatatable = ({ comments, deleteCommentModalOpen }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/comments/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={`/comments/update/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="updateButton">Update</div>
            </Link>
            {user.adminType === adminType.superAdmin && (
              <div
                onClick={() => deleteCommentModalOpen(params.row._id)}
                className="deleteButton"
              >
                Delete
              </div>
            )}
          </div>
        );
      },
    },

  ];
  const BooleanDropdown = ({ rowId, initialValue }) => {
    const [selectedValue, setSelectedValue] = useState(initialValue);
    
    const handleChange = async (event) => {
      const newValue = event.target.value === "true"; // Convert string to boolean
      console.log(newValue)
      setSelectedValue(newValue);
        try {
          const updatedData={publish:newValue}
          console.log(updatedData)
          const response=await Services.updateComment(rowId, updatedData);
          console.log(response)
        } catch (error) {
          const errorMessage = error.response?.data?.message || "Error!!";
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };

    return (
        <select value={selectedValue} onChange={handleChange} className="">
            <option value="true">True</option>
            <option value="false">False</option>
        </select>
    );
};
  const publishColumn = [
    {
      field: "publish",
      headerName: "Publish",
      width: 200,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div className="cellAction">
              <div key={params.row._id} className="cellAction">
                  <BooleanDropdown rowId={params.row._id} initialValue={params.row.publish} />
              </div>

            
          </div>
        );
      },
    },

  ];
  return (
    <>
      <div className="datatable">
        <div className="datatableTitle">
          Comments
          {/* <Link to="/comments/new" className="link">
            Add New
          </Link> */}
        </div>
        <DataGrid
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterLogicOperator: GridLinkOperator.Or,
              },
            },
          }}
          components={{ Toolbar: QuickSearchToolbar }}
          getRowId={(row) => row._id}
          className="datagrid"
          rows={comments}
          // columns={commentColumns.concat(actionColumn)}
          columns={[...publishColumn,...commentColumns,...actionColumn]}
          pageSize={20}
          rowsPerPageOptions={[20]}
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              overflowX: "scroll",
            },
          }}
        />
      </div>
    </>
  );
};

export default CommentDatatable;
